import React from 'react';
import { graphql, Link } from 'gatsby';
import { Box } from 'grommet';
import { TextSection, ButtonBanner, ServicesL3Hero, CityworksPowerBlock } from '../ui';
import htmlSerializer from '../prismic/htmlSerializer';
import Layout from '../layout/primary';

export const query = graphql`
  {
    prismic {
      content: allCityworkss {
        edges {
          node {
            intro_title
            intro_copy
            intro_image
            partner_product_title
            partner_title
            partner_copy
            partner_image
            product_title
            product_copy
            product_image
            approach_title
            approach_copy
            about_power360ams
            meta_title
            meta_description
            services {
              service_title
              service_text
            }
          }
        }
      }
    }
  }
`;

export default function({ data }) {
  const content = data.prismic.content.edges[0].node;
  const services = content.services.map((item) => {
    return {
      name: item.service_title,
      text: item.service_text,
    };
  });

  return (
    <Layout
      title={content.meta_title}
      description={content.meta_description}
      image={content.intro_image && content.intro_image.url}
    >
      <Box>
        <ServicesL3Hero
          headerText={content.intro_title}
          bodyText={content.intro_copy[0].text}
          image={content.intro_image}
          htmlSerializer={htmlSerializer}
        />
        <CityworksPowerBlock
          header1Text={content.partner_product_title}
          header2Text={content.partner_title}
          image1={content.partner_image}
          body2Text={content.partner_copy[0].text}
          header3Text={content.product_title}
          image2={content.product_image}
          body3Text={content.product_copy}
          htmlSerializer={htmlSerializer}
        />
        <ButtonBanner
          Link={Link}
          heading="See the projects where POWER has helped implement Cityworks®."
          button="View Projects"
          href="/library?type=Project&amp;term=Cityworks"
          htmlSerializer={htmlSerializer}
        />
      </Box>
    </Layout>
  );
}
